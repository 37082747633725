import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Troque HashRouter por BrowserRouter
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/js/all.js';
import Particles from './components/Particles';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Particles />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
